@use "definitions" as *;

$top-panel-height: 60px;
$comparison-picker-height: 50px;
$footer-height: 40px;

.comparison-header {
    display: flex;
    justify-content: end;
    padding: var(--spacing2) var(--spacing5);
    border-bottom: 1px solid lg-color(--gray-color-20);
    position: absolute;
    z-index: 2;
    background-color: lg-color(--white);

    &.comparison-header--detail-page {
        justify-content: space-between;
    }
}
