@use "@angular/cdk/overlay-prebuilt";
@use "definitions" as *;

body {
    overflow-x: auto;
}

.body__content {
    padding: var(--spacing2) var(--spacing5) !important;
    height: calc(100% - var(--spacing2) - var(--spacing2));
}
