.shared__logo-dica-registry {
    background-size: cover;
    width: 162px;
    height: 39px;
}

.shared__logo-dica-daci {
    background: url(./../logos/registries/logo-daci.svg) center no-repeat;
    width: 216px;
}

.shared__logo-dica-dasa {
    background: url(./../logos/registries/logo-dasa.svg) center no-repeat;
}

.shared__logo-dica-dato {
    background: url(./../logos/registries/logo-dato.svg) center no-repeat;
}

.shared__logo-dica-dbir {
    background: url(./../logos/registries/logo-dbir.svg) center no-repeat;
}

.shared__logo-dica-dcra,
.shared__logo-dica-dcrar {
    background: url(./../logos/registries/logo-dcra.svg) center no-repeat;
}

.shared__logo-dica-dgda {
    background: url(./../logos/registries/logo-dgda.svg) center no-repeat;
}

.shared__logo-dica-dgea {
    background: url(./../logos/registries/logo-dgea.svg) center no-repeat;
    width: 224px;
}

.shared__logo-dica-dgoa,
.shared__logo-dica-dgoar {
    background: url(./../logos/registries/logo-dgoa.svg) center no-repeat;
    width: 228px;
}

.shared__logo-dica-dhba {
    background: url(./../logos/registries/logo-dhba.svg) center no-repeat;
    width: 171px;
}

.shared__logo-dica-dhfa {
    background: url(./../logos/registries/logo-dhfa.svg) center no-repeat;
}

.shared__logo-dica-dhna {
    background: url(./../logos/registries/logo-dhna.svg) center no-repeat;
    width: 180px;
}

.shared__logo-dica-diha {
    background: url(./../logos/registries/logo-diha.svg) center no-repeat;
}

.shared__logo-dica-dlcal {
    background: url(./../logos/registries/logo-dlcal.svg) center no-repeat;
    width: 175px;
}

.shared__logo-dica-dlcar {
    background: url(./../logos/registries/logo-dlcar.svg) center no-repeat;
    width: 175px;
}

.shared__logo-dica-dlcas {
    background: url(./../logos/registries/logo-dlcas.svg) center no-repeat;
    width: 175px;
}

.shared__logo-dica-dma {
    background: url(./../logos/registries/logo-dma.svg) center no-repeat;
    width: 159px;
}

.shared__logo-dica-dmtr {
    background: url(./../logos/registries/logo-dmtr.svg) center no-repeat;
}

.shared__logo-dica-dnma {
    background: url(./../logos/registries/logo-dnma.svg) center no-repeat;
}

.shared__logo-dica-dpa {
    background: url(./../logos/registries/logo-dpa.svg) center no-repeat;
}

.shared__logo-dica-dpaa {
    background: url(./../logos/registries/logo-dpaa.svg) center no-repeat;
    width: 300px;
}

.shared__logo-dica-dpard {
    background: url(./../logos/registries/logo-dpard.svg) center no-repeat;
    width: 174px;
}

.shared__logo-dica-dpca {
    background: url(./../logos/registries/logo-dpca.svg) center no-repeat;
    width: 171px;
}

.shared__logo-dica-dppa {
    background: url(./../logos/registries/logo-dppa.svg) center no-repeat;
    width: 201px;
}

.shared__logo-dica-drce {
    background: url(./../logos/registries/logo-drce.svg) center no-repeat;
    width: 315px;
}

.shared__logo-dica-dsaa {
    background: url(./../logos/registries/logo-dsaa.svg) center no-repeat;
    width: 168px;
}

.shared__logo-dica-duca {
    background: url(./../logos/registries/logo-duca.svg) center no-repeat;
    width: 222px;
}

.shared__logo-dica-epsa {
    background: url(./../logos/registries/logo-epsa.svg) center no-repeat;
}

.shared__logo-dica-nbca,
.shared__logo-dica-nbcar {
    background: url(./../logos/registries/logo-nbca.svg) center no-repeat;
}
