@use "styles/logos";
@use "styles/sort-by-indicator";
@use "styles/comparison-header" as *;
@use "definitions" as *;

$min-body-width: 1024px;
$left-sidebar-width: 64px;
$right-sidebar-width: 360px;

.body__content {
    padding: 0;

    &.body__content--with-comparison-picker {
        .lg-scrollable {
            margin-top: $comparison-picker-height;
        }
    }
}

.general-page-content {
    padding: 0 var(--spacing5);

    &.general-page-content--with-comparison-picker {
        height: calc(
            100vh - $comparison-picker-height - $top-panel-height - $footer-height - var(--spacing3)
        );
    }
}

.breadcrumb-row {
    padding: var(--spacing2) var(--spacing5);
    width: calc(100% - var(--spacing7));
    margin-bottom: 0;
    border-bottom: 1px solid lg-color(--gray-color-20);
}

.lg-chart-legend {
    width: 100% !important;
}

.lg-chart__tooltip__table__item__value_column {
    white-space: nowrap;
}
