@use "./registries-logos";

.codman-tool-icon {
    background: transparent url("./../logos/tool-icon-codman.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.survey-tool-icon {
    background: transparent url("./../logos/tool-icon-survey.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.data-connect-tool-icon {
    background: transparent url("./../logos/tool-icon-data-connect.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.data-request-tool-icon {
    background: transparent url("./../logos/tool-icon-data-request.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.patients-tool-icon {
    background: transparent url("./../logos/tool-icon-patients.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.medicine-tool-icon {
    background: transparent url("./../logos/tool-icon-medicines.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.shared__logo-mrdm {
    background: url(./../logos/logo-mrdm.svg) center no-repeat;
}

.shared__logo-mrdm--black {
    background: url(./../logos/logo-mrdm-black.svg) center no-repeat;
}

// tenant logos
.shared__logo-dica {
    background: url(./../logos/logo-dica.svg) center no-repeat;
    background-size: contain;
    width: 140px;
    height: 35px;
}

.footer__logo-dica {
    background: url(./../logos/logo-dica-black.png) center no-repeat;
    background-size: contain;
}

.shared__logo-niv {
    background: url(./../logos/logo-niv.jpg);
    background-size: cover;
    width: 64px;
    height: 35px;
}

.shared__logo-swab {
    background: url(./../logos/logo-swab.png);
    background-size: cover;
    width: 196px;
    height: 35px;
}

.shared__logo-pice {
    background: url(./../logos/logo-pice.png);
    background-size: cover;
    width: 75px;
    height: 35px;
}

.shared__logo-rivm {
    background: url(./../logos/logo-rivm.svg) center no-repeat;
    background-size: cover;
    width: 89px;
    height: 35px;
}

.shared__logo-dicawgz {
    background: url(./../logos/logo-saz.svg) center no-repeat;
    background-size: cover;
    width: 91px;
    height: 35px;
}

.shared__logo-idr {
    background: url(./../logos/logo-idr.png);
    background-size: cover;
    width: 95px;
    height: 35px;
}
